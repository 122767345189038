const Footer = () => {
    return (
        <footer className="absolute inset-x-0 bottom-0 bg-primary border-t border-divider">
            <div className="max-w-7xl mx-auto py-4 px-4 flex justify-items-start items-center justify-center flex-wrap">
                <div className="flex justify-center space-x-6 order-2">&nbsp;</div>
            </div>
        </footer>
    );
};

export default Footer;
