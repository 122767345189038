type iconProps = {
	className: string;
};

const AppSettingsIcon = ({ className }: iconProps) => {
	return (
		<svg viewBox="0 0 260 260" fill="currentColor" className={className}>
			<path
				className="cls-1"
				d="M34.5,101.42H222.93c-4.82-3.63-9.04-6.57-12.89-9.93-.77-.67-.11-2.97-.11-5.48,8.37,5.77,16.23,11.12,23.99,16.63,.88,.62,1.31,2.03,1.72,3.15,5.87,16.04,11.76,32.08,17.53,48.16,3.03,8.44-.08,12.89-8.97,12.89-76.86,.03-153.73,.03-230.59,.01-8.8,0-11.93-4.6-8.91-12.95,5.75-15.9,11.53-31.79,17.41-47.65,.63-1.71,1.68-3.61,3.11-4.62,8.39-5.95,16.95-11.66,25.75-17.65,1.61,3.42,1.09,5.66-2,7.59-4.55,2.86-8.9,6.04-14.45,9.85Z"
			/>
			<path className="cls-1" d="M23.01,177.44H234.83c0,8.63,0,17.11,0,25.59,0,11,.04,21.99,0,32.99-.04,9.76-3.94,13.7-13.63,13.7-33.18,.02-66.36,0-99.54,0-27.59,0-55.18,0-82.78,0-12.62,0-15.87-3.2-15.87-15.67,0-18.67,0-37.35,0-56.62Z" />
			<path
				className="cls-1"
				d="M75.27,37.51c5.04-4.93,9.76-9.55,14.69-14.36,3.51,2.32,7.42,4.63,11.01,7.37,2.32,1.77,4.09,1.01,6.6,.48,8.73-1.86,11.45-7.73,11.99-15.63,.12-1.68,.77-3.33,1.2-5.1h20.11c.47,1.9,1.18,3.55,1.25,5.23,.33,8.09,3.33,13.67,12.07,15.51,2.6,.55,4.34,1,6.56-.63,3.51-2.57,7.25-4.83,10.95-7.26,4.7,4.96,9.32,9.84,14.19,14.98-.63,.96-1.51,2.68-2.73,4.12-4.58,5.43-6.52,10.66-2.14,17.63,2.22,3.53,3.73,6.18,8.22,6.47,3.24,.21,6.42,1.31,9.85,2.06v19.96c-13.08,5-26.84,2.46-40.81,3.18,4.27-15.25,1.18-27.62-11.7-36.1-10.44-6.87-21.74-6.79-32.05,.19-12.57,8.51-15.51,20.84-11.06,36.38-14.08-.7-27.52-1.37-41.15-2.04v-21.4c1.33-.35,2.48-.92,3.63-.93,9.16-.04,14.89-4.43,16.81-13.38,.41-1.9,.35-4.34-.53-5.99-2.02-3.81-4.68-7.28-6.97-10.73Z"
			/>
		</svg>
	);
};

export default AppSettingsIcon;
