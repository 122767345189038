import React from "react";
import {useAppDispatch} from "../../app/hooks";
import {changeRegisteredListingStatus, selectRegisteredListingById} from "../registeredListing/registeredListingSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {hide} from "./notificationSlice";

const DeleteModal = ({id}: {id: string}) => {
    const dispatch = useAppDispatch();
    const listing = useSelector((state: RootState) => selectRegisteredListingById(state, id));

    return (
        <div>
            <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Confirm delete Registered Listing
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Are you sure? This action cannot be undone.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {dispatch(changeRegisteredListingStatus({listing: listing, new_status: 'Z'})).then(() => dispatch(hide()))}}
                >
                    Delete
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => dispatch(hide())}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default DeleteModal;